import React from "react";

// import logo from "../assets/logo.svg";

import {Button} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";


const Hero = async () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();

  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get('from');
  const isGitBook = urlParams.get('gitbook');
  const state = urlParams.get('state');

  

  console.log("rediretTo", redirectTo);
  console.log("gitbook", isGitBook);
  console.log("state", state);

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: redirectTo,
        isGitBook: isGitBook
      }
    });
  } else {
    
    // setTimeout(async () => {
      const auth0AccessToken = await getAccessTokenSilently(); 
      console.log(auth0AccessToken);

      // const res = await handleRedirectCallback();
      // console.log(res);

      if (redirectTo != null) {
        if (isGitBook === "true") {

          const response = await fetch(
            "/auth",
            {
              method: "POST",
              body: JSON.stringify({"token": auth0AccessToken})
            }
          );
    
          const responseJson = await response.json();
          const redirectURL = `https://blockaid-1.gitbook.io/test-docs/?jwt_token=${responseJson["token"]}`;

          console.log(redirectURL);
          // setTimeout(() => {
          window.location = redirectURL;
          // }, 10000);
          
        } else {
          const redirectURL = `http://${redirectTo}/?jwt_token=${auth0AccessToken}`;
          console.log(redirectURL)

          console.log(redirectURL);
          // setTimeout(() => {
          window.location = redirectURL;
          // }, 10000);
        }
      }
  }

  return (
    <div className="text-center hero my-5">
      {isAuthenticated ? (
        <div>
          <p>Welcome, {user.name}!</p>
          <Button
            onClick={() => logout()}
          >
            Logout
          </Button>
        </div>
      ) : (
        <p></p>
        // <Button
        //   id="qsLoginBtn"
        //   color="primary"
        //   className="btn-margin"
        //   onClick={() => loginWithRedirect()}
        // >
        //   Log in
        // </Button>
      )}
    </div>
  )
};

export default Hero;
